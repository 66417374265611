import { endOfDay, isPast } from 'date-fns'
import { Competition } from '../_app/types/firestore.ts'
import { getFirstDate, getLastCompetitionDate } from '../_app/utils'

type PageState = {
  competitions: Competition[]
  pastCompetitions: Competition[]
}
export const groupCompetitions = (competitions: Competition[]): PageState => {
  const state: PageState = {
    competitions: [],
    pastCompetitions: [],
  }

  const sorted = competitions.slice().sort((a, b) => {
    const aDate = getFirstDate(a.dates)
    const bDate = getFirstDate(b.dates)

    if (aDate < bDate) {
      return 1
    }

    if (aDate > bDate) {
      return -1
    }

    return 0
  })

  sorted.forEach(item => {
    if (isPast(endOfDay(getLastCompetitionDate(item.dates)))) {
      state.pastCompetitions.push(item)
    } else {
      state.competitions.unshift(item)
    }
  })

  return state
}
